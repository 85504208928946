import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 0dvh;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px;

    span {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    p {
      margin-left: 10px;
      font-weight: 500;
    }

    b {
      font-weight: 500;
    }
  }

  img {
    width: 2%;
  }

  hr {
    width: 100%;
    border-width: 1px;
    border-color: #ccc;
  }

  @media screen and (max-width: 767px){
    img {
      width: 10%;
    }
    div b {
      visibility: hidden;
    }
  }
`;
