import React, { useEffect, useState } from 'react';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { GlobalStyles } from './styles/GlobalStyles';

import RouterComponent from './components/RouterComponent';
import Context, { IContext } from './context/Context';

import { IUsuario } from './types/Usuario';
import { IEmpresa } from './types/Empresa';
import { IConfig } from './types/Config';

window.document.title = import.meta.env.VITE_EMPRESA;

function App() {
  const [usuario, setUsuario] = useState<IUsuario | undefined>();
  const [empresa, setEmpresa] = useState<IEmpresa | undefined>();
  const [configs, setConfigs] = useState<IConfig[]>([]);
  const [isLoadingOverlay, setIsLoadingOverlay] = useState<boolean>(false);

  const contextValues: IContext = {
    configs,
    setConfigs,
    isLoadingOverlay,
    setIsLoadingOverlay,
    usuario,
    setUsuario,
    empresa,
    setEmpresa
  }

  return (
    <Context.Provider value={contextValues}>
      <GlobalStyles />
      <ToastContainer
        style={{ zIndex: 999999999999 }}
        position='top-right'
        theme='colored'
      />
      <LoadingOverlayWrapper
        active={isLoadingOverlay}
        spinner
        text='Aguarde...'
      >
        <RouterComponent />
      </LoadingOverlayWrapper>
    </Context.Provider>
  )
}

export default App;
