import React, { useContext, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  useNavigate,
  useLocation
} from 'react-router-dom';
import Context, { IContext } from '../../context/Context';
import { toast } from 'react-toastify';

import { Login } from '../../pages/Login';
import ErrorPage from '../../pages/ErrorPage';
import Menu from '../../pages/Menu';
import Usuario from '../../pages/Usuario';
import ReservaDetalhes from '../../pages/ReservaDetalhes';

import Header from '../Header';
import Cookies from 'js-cookie';

function LayoutFixo() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { setUsuario }: IContext = useContext(Context);

  function getCookies() {
    const usuarioCookie = Cookies.get('usuarioCookie');

    if (!usuarioCookie && pathname !== '/') {
      toast.warning('Necessario realizar o login');
      navigate('/');
      return;
    }

    if (!usuarioCookie) return;

    setUsuario(JSON.parse(usuarioCookie));
  }

  useEffect(() => {
    getCookies();
  }, [pathname]);

  return (
    <>
      <Header />
      <Outlet />
    </>
  );
}

export default function RouterComponent() {
  return (
    <>
      <Router>
        <Routes>
          <Route element={<LayoutFixo />}>
            <Route path='/' element={<Login />} />
            <Route path='/menu' element={<Menu />} />
            <Route path='/usuario/:metodo' element={<Usuario />} />
            <Route path='/usuario/:metodo/:form' element={<Usuario />} />
            <Route path='/usuario/:metodo/:form/:slide' element={<Usuario />} />
            <Route path='/reservaDetalhes/:cod' element={<ReservaDetalhes />} />
          </Route>
          <Route path='*' element={<ErrorPage />} />
        </Routes>
      </Router>
    </>
  );
}
