import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import styled from 'styled-components';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Importa o CSS padrão

const AlertContainer = styled.div`
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: auto;
`;

const Title = styled.h1`
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 16px;
`;

const Message = styled.p`
  margin-bottom: 8px;
`;

const Caption = styled.p`
  font-weight: bold;
  margin-bottom: 8px;
  font-size: 0.7rem;

  a {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Button = styled.button`
  background-color: #000;
  color: #fff;
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: bold;
  margin-left: 8px;
  cursor: pointer;

  &:hover {
    background-color: #333;
  }
`;

const CustomAlert = ({ title, message, onConfirm, onCancel } : any) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <AlertContainer>
          <Title>{title}</Title>
          <Message>{message}</Message>
          <Caption>Ao Confirmar Você Está de Acordo com os <a href='https://www.afmm.com.br' target='_blank' rel='noreferrer'>Termos e Condições de Uso</a> da Plataforma.</Caption>
          <ButtonContainer>
            <Button
              onClick={() => {
                onCancel();
                onClose();
              }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => {
                onConfirm();
                onClose();
              }}
            >
              Confirmar
            </Button>
          </ButtonContainer>
        </AlertContainer>
      );
    }
  });
};

export default CustomAlert;
