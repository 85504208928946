import styled from 'styled-components';

interface Props {
  $editando?: boolean;
}

export const TableContainer = styled.div`
  margin: 0px 20px;
  font-family: Arial, sans-serif;
  text-align: center;

  h1 {
    font-size: 1.5rem;
    font-weight: 500;
  }

  button {
    font-size: 0.7rem;
  }

  input {
    font-size: 0.8rem;
    text-align: center;
  }

  @media screen and (max-width: 767px) {
    font-size: 0.7rem;

    h1 {
      margin: 1rem 0px;
    }
  }
`;

export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto; /* Adiciona rolagem horizontal se necessário */
  -webkit-overflow-scrolling: touch; /* Melhora a rolagem em dispositivos iOS */
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
`;

export const Th = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  background-color: #bfbfbf;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: 767px) {
    padding: 6px;
    font-size: 0.6rem;
  }
`;

export const Td = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: 767px) {
    padding: 6px;
    font-size: 0.6rem;
  }
`;

export const ActionTd = styled(Td)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  min-height: 40px;
  font-size: 1.5rem;
  max-width: 100px;

  svg {
    cursor: pointer;
  }

  svg:hover {
    opacity: 0.8;
  }

  @media screen and (max-width: 767px) {
    font-size: 1.2rem;
    gap: 10px;
  }
`;

export const Tr = styled.tr<Props>`
  ${({ $editando }) => $editando && 'background-color: #bfbfbf;'}

  &:hover {
    background-color: #bfbfbf;
  }

  @media screen and (max-width: 767px) {
    /* Ajusta a altura da linha se necessário */
  }
`;
