import React from 'react';
import { Container } from './styles';

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  placeHolder: string;
  animated?: boolean;
}

export default function FormInput({ placeHolder, animated, ...rest}: IProps) {
  return (
    <Container $animated={animated}>
      <input {...rest} placeholder=' ' />
      <span>{placeHolder}</span>
    </Container>
  );
}
