import styled from 'styled-components';

export const Container = styled.main`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  min-height: 100dvh;
  margin-top: 4rem;
`;
