import React from 'react';

import { CustomButtom } from './styles';

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  texto: string;
  animated?: boolean;
}

export default function Button({ texto, animated , ...rest }: IProps) {
  return (
    <CustomButtom {...rest} $animated={animated}>{texto || 'Button'}</CustomButtom>
  );
}
