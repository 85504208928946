import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { Container, SelectWrapper, Select, Label, Option, SelectContainer } from './styles';

import api from '../../services/api';
import { ITipEsp } from '../../types/TipEsp';
import { IEspaco } from '../../types/Espaco';
import Button from '../Button';
import AgendaReserva from '../AgendaReserva';
import { useNavigate } from 'react-router-dom';

export default function NovaReserva({ slide }: { slide: string }) {
  const navigate = useNavigate();

  const [tiposDeEspaco, setTiposDeEspaco] = useState<ITipEsp[]>([]);
  const [tipoDeEspacoSelecionado, setTipoDeEspacoSelecionado] = useState<ITipEsp | undefined>();
  const [espacos, setEspacos] = useState<IEspaco[]>([]);
  const [espacoSelecionado, setEspacoSelecionado] = useState<IEspaco | undefined>();
  const [indexCarousel, setIndexCarousel] = useState<number>(0);

  async function getTiposDeEspaco() {
    try {
      const response = await api.get('/tiposDeEspaco');

      if (response.status === 200) {
        setTiposDeEspaco(response.data);
      }
    } catch (error: any) {
      toast.error('Erro ao buscar Tipos de Espaço');
    }
  }

  async function getEspacos(codTipEsp: number) {
    try {
      setEspacoSelecionado(undefined);

      const response = await api.get('/espacos', {
        params: {
          codtipesp: codTipEsp
        }
      });

      if (response.status === 200) {
        setEspacos(response.data);
      }
    } catch (error) {
      toast.error('Erro ao buscar Espaços');
    }
  }

  useEffect(() => {
    getTiposDeEspaco();
  }, []);

  useEffect(() => {
    if (slide === 'agenda') return setIndexCarousel(1);

    setIndexCarousel(0);
  }, [slide]);

  useEffect(() => {
    if (tipoDeEspacoSelecionado?.cod) {
      getEspacos(tipoDeEspacoSelecionado.cod);
    }
  }, [tipoDeEspacoSelecionado]);

  return (
    <Container>
      <Carousel
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        swipeable={false}
        emulateTouch={false}
        infiniteLoop={false}
        showIndicators={false}
        autoPlay={false}
        selectedItem={indexCarousel}
        onChange={setIndexCarousel}
        className='carousel'
      >
        <SelectContainer>
            <h1>Nova Reserva</h1>
          <SelectWrapper>
            <Label>Escolha o Tipo de Espaço para Reserva:</Label>
            <Select
              onChange={e => setTipoDeEspacoSelecionado(tiposDeEspaco.find(tipoDeEspaco => tipoDeEspaco.cod === +e.target.value))}
              value={tipoDeEspacoSelecionado?.cod || ''}
            >
              <Option value='' disabled>Selecione o Tipo de Espaço</Option>
              {tiposDeEspaco.map((tipoDeEspaco: ITipEsp, index) => (
                <Option key={index} value={tipoDeEspaco.cod}>{tipoDeEspaco.tipesp}</Option>
              ))}
            </Select>
          </SelectWrapper>

          {tipoDeEspacoSelecionado && (
            <SelectWrapper>
              <Label>Escolha o Espaço para a Reserva:</Label>
              <Select
                onChange={e => setEspacoSelecionado(espacos.find(espaco => espaco.cod === +e.target.value))}
                value={espacoSelecionado?.cod || ''}
              >
                <Option value='' disabled>Selecione o Espaço</Option>
                {espacos.map((espaco: IEspaco, index) => (
                  <Option key={index} value={espaco.cod}>{espaco.esp}</Option>
                ))}
              </Select>
            </SelectWrapper>
          )}

          {espacoSelecionado &&
            <Button
              texto='Consultar Disponibilidade'
              onClick={() => {
                navigate('/usuario/cadastro/novaReserva/agenda');
                setIndexCarousel(1);
              }}
              style={{ width: '90%', marginTop: '20px' }}
              animated
            />}
        </SelectContainer>
        <>{espacoSelecionado && indexCarousel === 1 && <AgendaReserva espaco={espacoSelecionado} />}</>
      </Carousel>
    </Container>
  );
}
