import styled, { keyframes } from 'styled-components';
import { zoomIn } from 'react-animations';

interface ButtonProps {
  $animated?: boolean;
}

const animationType = keyframes`${zoomIn}`;

export const CustomButtom = styled.button<ButtonProps>`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  width: 80%;
  font-weight: 500;
  color: #FFF;
  background-color: #000;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  animation: 0.5s ${({ $animated }) => ($animated ? animationType : 'none')};

  &:hover {
    background-color: #333;
  }
`;
