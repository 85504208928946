import React, { useContext } from 'react';
import { Container } from './styles';
import Context, { IContext } from '../../context/Context';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Header() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { usuario }: IContext = useContext(Context);

  function handleNavigate() {
    if (pathname === '/') return;

    if (usuario) {
      navigate('/menu');
      return;
    }

    navigate('/');
  }

  return (
    <Container>
      <div>
        <span onClick={handleNavigate}>
          <img src={'https://imagizer.imageshack.com/img922/545/PK5ZMv.jpg'} alt='logo' />
          <p>{import.meta.env.VITE_EMPRESA}</p>
        </span>
        {usuario?.usu ? <b>{`${usuario.tip}: ${usuario?.usu}`}</b> : null}
      </div>
      <hr />
    </Container>
  );
}
