import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie'

import { Container, Form } from './styles';

import FormInput from '../../components/FormInput';
import Button from '../../components/Button';
import Copyright from '../../components/Copyright';
import { ILogin } from '../../types/Login';
import Context, { IContext } from '../../context/Context';

import { cpfMask } from '../../utils/validaCpfCnpj';
import api from '../../services/api';

interface IFormDataAssociado {
  cgc: string;
  sen?: string;
  tip: 'ASSOCIADO';
}

interface IFormDataAdministradorControle {
  cgc: string;
  sen: string;
  tip: 'ADMINISTRADOR' | 'CONTROLE';
}

type IFormData = IFormDataAssociado | IFormDataAdministradorControle;

export function Login() {
  const navigate = useNavigate();
  const { usuario, setUsuario, setIsLoadingOverlay }: IContext = useContext(Context);

  const [formData, setFormData] = useState<IFormData>({
    cgc: '',
    sen: '',
    tip: 'ASSOCIADO',
  } as IFormDataAssociado);

  function handleToggleTip() {
    setFormData(prevState => {
      if (prevState.tip === 'ASSOCIADO') {
        return { ...prevState, tip: 'ADMINISTRADOR', sen: '' } as IFormDataAdministradorControle;
      }
      return { ...prevState, tip: 'ASSOCIADO', sen: undefined } as IFormDataAssociado;
    });
  };

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const payload: ILogin = {
      cgc: formData.cgc,
      sen: formData.sen
    }

    try {
      setIsLoadingOverlay(true);

      const response = await api.post('/login', payload);

      if (response.status === 200) {
        Cookies.set('usuarioCookie', JSON.stringify(response.data), { expires: 1 });
        setUsuario(response.data);
        toast.success(`Seja bem-vindo ${response.data.usu}`);
        navigate('/menu');
      }
    } catch (error: any) {
      if (error.response.data?.erro.includes('Senha é obrigatória para o tipo de Usuário CONTROLE e ADMINISTRADOR')) {
        setFormData(prevState => ({ ...prevState, tip: 'ADMINISTRADOR', sen: '' } as IFormDataAdministradorControle));
        return toast.warning('Informe a senha de acesso.');
      };
      if (error.response.data) return toast.error(error.response.data.erro);

      toast.error(error.message);
    } finally {
      setIsLoadingOverlay(false);
    }
  }

  useEffect(() => {
    if (usuario) setUsuario(undefined);
  }, [usuario]);

  return (
    <Container onSubmit={handleSubmit}>
      <Form>
        <img src={'https://imagizer.imageshack.com/img922/545/PK5ZMv.jpg'} alt='logo' />
        <h1>Bem-vindo a Locação AFMM</h1>
        <h4>Acesse utilizando CPF do Associado Titular</h4>
        <FormInput
          placeHolder='CPF'
          type='text'
          required
          maxLength={14}
          onChange={e => setFormData({ ...formData, cgc: cpfMask(e.target.value) })}
          value={formData.cgc}
        />
        {formData.tip !== 'ASSOCIADO' &&
          <FormInput
            placeHolder='Senha'
            type='password'
            required
            onChange={e => setFormData({ ...formData, sen: e.target.value })}
            value={formData.sen}
            animated
          />
        }
        <Button texto='Acessar' type='submit' />
        <p
          onClick={handleToggleTip}
        >
          Entrar como {formData.tip === 'ASSOCIADO' ? 'Administrador' : 'Associado'}
        </p>
      </Form>
      <Copyright />
    </Container>
  )
}
