import React, { useContext, useEffect, useState } from 'react';

import { Container } from './styles';
import { useParams } from 'react-router-dom';
import Context, { IContext } from '../../context/Context';
import CadastroGenerico, { ICampos, IFormatoCampos } from '../../components/CadastroGenerico';
import { cpfMask } from '../../utils/validaCpfCnpj';

export default function ReservaDetalhes() {
  const { cod } = useParams();

  function getCadastroProps(entidade: string, endpoint: string, campos: ICampos[], formatoCampos?: IFormatoCampos) {
    return {
      entidade,
      endpoint,
      campos,
      formatoCampos
    }
  }

  const campos = [
    {
      campo: 'con',
      label: 'Nome Completo',
      type: 'text'
    }
  ] as ICampos[];

  if (import.meta.env.VITE_CODEMP !== '1') {
    campos.push({ campo: 'cgc', label: 'CPF/CNPJ', type: 'text' });
  }

  return (
    <Container>
      <CadastroGenerico
        {...getCadastroProps(
          'Convidado',
          '/convidados',
          campos,
          {
            cgc: cpfMask
          }
        )}
        codReserva={Number(cod)}
      />
    </Container>
  );
}
