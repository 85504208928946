import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Container } from './styles';

import TabelaReservas from '../../components/TabelaReservas';
import Button from '../../components/Button';
import Agenda from '../../components/Agenda';
import CadastroGenerico, { ICampos, IFormatoCampos } from '../../components/CadastroGenerico';
import NovaReserva from '../../components/NovaReserva';

import { formatCurrency } from '../../utils/formatCurrency';
import Context, { IContext } from '../../context/Context';

export default function Usuario() {
  const navigate = useNavigate();
  const { metodo, form, slide } = useParams();

  const { usuario }: IContext = useContext(Context);

  function getCadastroProps(entidade: string, endpoint: string, campos: ICampos[], formatoCampos?: IFormatoCampos) {
    return {
      entidade,
      endpoint,
      campos,
      formatoCampos
    }
  }

  if (metodo === 'reservas') {
    return (
      <Container>
        <TabelaReservas datres={new Date().toISOString().substring(0, 10)} />
      </Container>
    );
  }

  if (metodo === 'minhasReservas') {
    return (
      <Container>
        <TabelaReservas codusu={usuario?.cod} />
      </Container>
    );
  }

  if (metodo === 'agenda') {
    return (
      <Container>
        <Agenda />
      </Container>
    );
  }

  if (metodo === 'cadastro') {
    if (form === 'novaReserva') {
      return (
        <Container>
          <NovaReserva slide={slide || ''}/>
        </Container>
      );
    }

    if (form === 'espaco') {
      return (
        <Container>
          <CadastroGenerico
            {...getCadastroProps(
              'Espaço',
              '/espacos',
              [
                { campo: 'esp', label: 'Espaço', type: 'text' },
                { campo: 'des', label: 'Descricão', type: 'text' },
                { campo: 'cod', label: 'Tipo de Espaço', type: 'select', options: [] }
              ],
              {}
            )}
          />
        </Container>
      );
    }

    if (form === 'tipoEspaco') {
      return (
        <Container>
          <CadastroGenerico
            {...getCadastroProps(
              'Tipo de Espaço',
              '/tiposDeEspaco',
              [
                { campo: 'tipesp', label: 'Tipo de Espaço', type: 'text' },
                { campo: 'valseg', label: 'Valor Segunda', type: 'number' },
                { campo: 'valter', label: 'Valor Terça', type: 'number' },
                { campo: 'valqua', label: 'Valor Quarta', type: 'number' },
                { campo: 'valqui', label: 'Valor Quinta', type: 'number' },
                { campo: 'valsex', label: 'Valor Sexta', type: 'number' },
                { campo: 'valsab', label: 'Valor Sábado', type: 'number' },
                { campo: 'valdom', label: 'Valor Domingo', type: 'number' },
                { campo: 'limcon', label: 'Limite de Convidados', type: 'number' },
                { campo: 'prares', label: 'Disponibilidade', type: 'number' },
              ],
              {
                valseg: formatCurrency,
                valter: formatCurrency,
                valqua: formatCurrency,
                valqui: formatCurrency,
                valsex: formatCurrency,
                valsab: formatCurrency,
                valdom: formatCurrency
              }
            )}
          />
        </Container>
      );
    }

    if (form === 'valoresEspeciais') {
      return (
        <Container>
          <CadastroGenerico
            {...getCadastroProps(
              'Datas Especiais',
              '/datasEspeciais',
              [
                { campo: 'cod', label: 'Tipo de Espaço', type: 'select', options: [] },
                { campo: 'dat', label: 'Data', type: 'date' },
                { campo: 'val', label: 'Valor', type: 'text' },
              ],
              {
                dat: (value: string) => value.substring(0, 10),
                val: formatCurrency
              }
            )}
          />
        </Container>
      );
    }
  }

  return (
    <Container>
      <h1>Página Não Encontrada</h1>
      <Button
        texto='Voltar Para o Menu'
        type='button'
        onClick={() => navigate('/menu')}
        style={{ width: '10%' }}
      />
    </Container>
  );
}
