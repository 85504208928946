import styled, { keyframes } from 'styled-components';
import { zoomIn } from 'react-animations';

const animationType = keyframes`${zoomIn}`;

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100dvh;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 524px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 100;
  animation: 0.5s ${animationType};
  text-align: center;

  img {
    width: 20%;
  }

  h4 {
    font-weight: 500;
    margin-bottom: 10px;
  }

  p {
    margin-top: 5px;
    font-size: 14px;
    text-align: center;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 767px){
    width: 90%;
  }

`;
