import styled, { keyframes } from 'styled-components';
import { fadeInLeft } from 'react-animations';

const animation = keyframes`${fadeInLeft}`;

export const Container = styled.main`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ButtonsContainer = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media screen and (max-height: 800px){
    margin-top: 4rem;
  }
`;

export const BotaoMenu = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  color: #FFF;
  background-color: #000;

  font-size: 3rem;
  margin: 10px 10px;
  box-shadow: 0px 2px 2px lightgray;
  transition: ease background-color 250ms;
  animation: 1s ${animation};

  height: 180px;
  width: 180px;
  border-radius: 5px;

  span {
    font-size: 1rem;
    font-weight: 500;
  }

  &:hover {
    background-color: #333;
  }

  @media screen and (max-width: 767px){
    font-size: 2rem;
    height: 120px;
    width: 120px;

    span {
      font-size: 0.8rem;
    }

  }

`;
