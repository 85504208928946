import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { Table, TableContainer, TableWrapper, Td, Th, Tr } from './styles';

import Button from '../Button';

import { IReserva } from '../../types/Reserva';
import api from '../../services/api';

import Context, { IContext } from '../../context/Context';
import { tratarData } from '../../utils/tratarData';

interface ITabelaReservas {
  codusu?: number;
  datres?: string;
}

export default function TabelaReservas({ codusu, datres }: ITabelaReservas) {
  const navigate = useNavigate();
  const { setIsLoadingOverlay }: IContext = useContext(Context);
  const [reservas, setReservas] = useState<IReserva[]>([]);

  async function getReservas() {
    try {
      setIsLoadingOverlay(true);
      const response = await api.get('/reservas', {
        params: {
          datres: datres,
          codusu: codusu,
          sta: 'PAGO'
        }
      });

      if (response.status === 200) {
        setReservas(response.data);
      }
    } catch (error: any) {
      if (error.response.data) return toast.error(error.response.data.erro);
      toast.error('Erro ao buscar reservas');
    } finally {
      setTimeout(() => {
        setIsLoadingOverlay(false);
      }, 500);
    }
  }

  useEffect(() => {
    getReservas();
  }, []);

  return (
    <TableContainer>
      <h1>
        {datres ? `Reservas do Dia` : 'Minhas Reservas'}
      </h1>
      <br />
      <TableWrapper>
        <Table>
          <thead>
            <Tr>
              <Th>Data Reservada</Th>
              <Th>Tipo</Th>
              <Th>Espaço</Th>
              {!codusu && <Th>Responsável</Th>}
              <Th>Ações</Th>
            </Tr>
          </thead>
          <tbody>
            {reservas.map((reserva, index) => (
              <Tr key={index}>
                <Td>{reserva.datres}</Td>
                <Td>{reserva.espaco.tipesp.tipesp}</Td>
                <Td>{reserva.espaco.esp}</Td>
                {!codusu && <Td>{reserva.usuario.usu}</Td>}
                <Td>
                  <Button
                    texto='Convidados'
                    type='button'
                    style={{ width: '100%' }}
                    onClick={() => navigate(`/reservaDetalhes/${reserva.cod}`)}
                  />
                </Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      </TableWrapper>
    </TableContainer>
  );
}
