export function tratarData(isoString?: string): string {
  if (!isoString) return '';

  // Parse da string ISO
  const date = new Date(isoString);

  // Extração dos componentes da data e hora
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Os meses são indexados a partir de 0
  const year = date.getFullYear();

  // Montagem da string no formato desejado
  return `${day}/${month}/${year}`;
};

export function destratarData(data: string): string {
  if (!data || data.length !== 10) return '';
  const [day, month, year] = data.split('/');
  return `${year}-${month}-${day}`;
}
